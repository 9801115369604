import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Image,
  Radio,
  Modal,
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import {
  IconArrowLeft,
  IconLock,
  IconMobile,
  IconPhone,
  IconUser,
} from '@arco-design/web-react/icon';
import React, { useEffect, useRef, useState } from 'react';
import useStorage from '@/utils/useStorage';
import styles from './style/index.module.less';
import { auth, profile } from '@/request/api';
import { useDispatch } from 'react-redux';
import { protocolContent } from './Constants';
export default function LoginForm(props: {
  onTypeChange?: (arg: number) => void;
  userType: number;
}) {
  const dispatch = useDispatch();
  const formRef = useRef<FormInstance>();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginParams, setLoginParams, removeLoginParams] =
    useStorage('loginParams');

  const [protocolAgree, setProtocolAgree] = useState(false);

  const [rememberPassword, setRememberPassword] = useState(!!loginParams);

  function afterLoginSuccess(params, accessToken) {
    // 记住密码
    if (rememberPassword) {
      setLoginParams(JSON.stringify(params));
    } else {
      removeLoginParams();
    }
    // 记录登录状态
    localStorage.setItem('token', accessToken);
    dispatch({
      type: 'update-token',
      payload: { token: accessToken },
    });
    dispatch({
      type: 'update-login-visible',
      payload: { loginVisible: false },
    });

    profile({}).then((res) => {
      dispatch({
        type: 'update-userInfo',
        payload: { userInfo: res.data.detail, userLoading: false },
      });
    });
  }

  function login(params) {
    setErrorMessage('');
    if (!protocolAgree) {
      Modal.confirm({
        icon: null,
        okText: '我已阅读并同意',
        onOk: () => {
          setProtocolAgree(true);
          loginProcess(params);
        },
        cancelText: '我不同意',
        onCancel: () => {
          setProtocolAgree(false);
        },
        style: {
          width: 700,
        },
        title: '用户隐私协议',
        content: protocolContent,
      });
      return;
    }
    loginProcess(params);
  }

  function loginProcess(params) {
    setLoading(true);
    auth({ ...params, userType: props.userType })
      .then((resp) => {
        if (resp.code === 200) {
          afterLoginSuccess(params, resp.data.token);
        } else {
          setErrorMessage(resp.message || '登录失败，请稍后重试');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function onSubmitClick() {
    formRef.current.validate().then((values) => {
      login(values);
    });
  }

  // 读取 localStorage，设置初始值
  useEffect(() => {
    const rememberPassword = !!loginParams;
    setRememberPassword(rememberPassword);
    if (formRef.current && rememberPassword) {
      const parseParams = JSON.parse(loginParams);
      formRef.current.setFieldsValue(parseParams);
    }
  }, [loginParams]);

  return (
    <div className={styles['login-form-wrapper']}>
      <div style={{ textAlign: 'left', marginBottom: 10 }}>
        <Link
          onClick={() => {
            props.onTypeChange(0);
          }}
        >
          <IconArrowLeft />
          &nbsp; 返回重新选择
        </Link>
      </div>
      <div className={styles['login-form-title']} style={{ textAlign: 'left' }}>
        {props.userType == 1 ? '用户' : '专家'}登录
      </div>
      <div className={styles['login-form-error-msg']}>{errorMessage}</div>
      <Form
        className={styles['login-form']}
        layout="vertical"
        ref={formRef}
        initialValues={{ account: '', password: '', protocol: false }}
      >
        <Form.Item
          field="account"
          label={<span>手机号</span>}
          requiredSymbol={true}
          rules={[{ required: true, message: '请输入11位手机号' }]}
        >
          <Input
            prefix={<IconMobile style={{ fontSize: 20 }} />}
            maxLength={11}
            placeholder="请输入11位手机号"
            onPressEnter={onSubmitClick}
            className={styles['login-form-input']}
          />
        </Form.Item>
        <Form.Item
          field="password"
          label={<span>密码</span>}
          requiredSymbol={true}
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <Input.Password
            prefix={<IconLock style={{ fontSize: 20 }} />}
            placeholder="请输入密码"
            onPressEnter={onSubmitClick}
            className={styles['login-form-input']}
          />
        </Form.Item>
        <Form.Item label={''} requiredSymbol={true}>
          <Checkbox
            checked={protocolAgree}
            onChange={(v) => {
              setProtocolAgree(v);
            }}
          />
          &nbsp; 我已阅读并同意
          <Link
            onClick={(e) => {
              Modal.confirm({
                icon: null,
                okText: '我已阅读并同意',
                onOk: () => {
                  setProtocolAgree(true);
                },
                cancelText: '我不同意',
                onCancel: () => {
                  setProtocolAgree(false);
                },
                style: {
                  width: 700,
                },
                title: '用户隐私协议',
                content: protocolContent,
              });
            }}
          >
            《隐私协议》
          </Link>
        </Form.Item>
        <Space size={16} direction="vertical">
          <Button type="primary" long onClick={onSubmitClick} loading={loading}>
            登录
          </Button>
        </Space>
        <div
          style={{
            width: '100%',
            marginTop: '16px',
          }}
        >
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              type="text"
              long
              className={styles['login-form-register-btn']}
              onClick={() => {
                props.onTypeChange(2);
              }}
            >
              立即注册
            </Button>
            <Button
              type="text"
              long
              className={styles['login-form-register-btn']}
              onClick={() => {
                props.onTypeChange(3);
              }}
            >
              忘记密码
            </Button>
          </Space>
        </div>
      </Form>
    </div>
  );
}
