/* eslint-disable */
import request from './request';

/**
 * 登录
 *
 * @param data
 * @returns
 */
export async function systemSettingGet(data: any) {
  return request('/apiService/member/systemSetting/get', {
    data,
    method: 'post',
  });
}
/**
 * 登录
 *
 * @param data
 * @returns
 */
export async function auth(data: any) {
  return request('/apiService/member/login', {
    data,
    method: 'post',
  });
}
/**
 * 注册
 *
 * @param data
 * @returns
 */
export async function register(data: any) {
  return request('/apiService/member/register', {
    data,
    method: 'post',
  });
}

/**
 * 通过短信验证码重设密码
 *
 * @param data
 * @returns
 */
export async function resetPasswordByVerifyCode(data: any) {
  return request('/apiService/member/password/reset', {
    data,
    method: 'post',
  });
}

/**
 * 发送短信验证码（注册）
 *
 * @param data
 * @returns
 */
export async function smsSendOnRegister(data: any) {
  return request('/apiService/member/verifyCode/register/send', {
    data,
    method: 'post',
  });
}

/**
 * 发送短信验证码（重置密码）
 *
 * @param data
 * @returns
 */
export async function smsSendOnResetPassword(data: any) {
  return request('/apiService/member/verifyCode/resetPassword/send', {
    data,
    method: 'post',
  });
}

/**
 * 获取个人资料
 *
 * @param data
 * @returns
 */
export async function profile(data: any) {
  return request('/apiService/member/profile', {
    data,
    method: 'get',
  });
}

/**
 * 获取OSS上传凭证
 *
 * @param data
 * @returns
 */
export async function commonOssStsTokenGet(data: any) {
  return request('/apiService/member/oss/stsToken', {
    data,
    method: 'get',
  });
}

/**
 * 获取搜索配置
 *
 * @param data
 * @returns
 */
export async function expertSearchOptions(data: any) {
  return request('/apiService/member/expert/searchOptions', {
    data,
    method: 'get',
  });
}

/**
 * 获取专家列表
 *
 * @param data
 * @returns
 */
export async function expertPaginate(data: any) {
  return request('/apiService/member/expert/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 获取专家列表
 *
 * @param data
 * @returns
 */
export async function expertListById(data: any) {
  return request('/apiService/member/expert/listById', {
    data,
    method: 'post',
  });
}

/**
 * 获取邀请资料字段配置
 *
 * @param data
 * @returns
 */
export async function inviteFieldSettingList(data: any) {
  return request('/apiService/member/inviteFieldSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 邀请专家
 *
 * @param data
 * @returns
 */
export async function inviteCreate(data: any) {
  return request('/apiService/member/invite/create', {
    data,
    method: 'post',
  });
}

/**
 * 我的项目列表
 *
 * @param data
 * @returns
 */
export async function invitePaginate(data: any) {
  return request('/apiService/member/invite/paginate', {
    data,
    method: 'get',
  });
}

/**
 * 我的项目列表
 *
 * @param data
 * @returns
 */
export async function inviteDetail(data: any) {
  return request('/apiService/member/invite/detail', {
    data,
    method: 'get',
  });
}

/**
 * 获取评价配置列表
 *
 * @param data
 * @returns
 */
export async function evaluationSettingList(data: any) {
  return request('/apiService/member/evaluationSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 评价项目
 *
 * @param data
 * @returns
 */
export async function inviteEvaluate(data: any) {
  return request('/apiService/member/invite/evaluate', {
    data,
    method: 'post',
  });
}

/**
 * 获取专家领域设置
 *
 * @param data
 * @returns
 */
export async function professionalFieldSettingList(data: any) {
  return request('/apiService/member/professionalFieldSetting/list', {
    data,
    method: 'get',
  });
}

/**
 * 修改个人资料
 *
 * @param data
 * @returns
 */
export async function profileUpdate(data: any) {
  return request('/apiService/member/profile/update', {
    data,
    method: 'post',
  });
}
