import { commonOssStsTokenGet } from '@/request/api';
import { Modal, Upload } from '@arco-design/web-react';
import React, { useEffect, useState } from 'react';
import OSS from 'ali-oss';
import { uploadToOss } from '@/utils/oss';

export default function CustomUpload(props: {
  value?: string[] | string;
  onChange?: (value: any) => void;
  forbidWaterMark?: boolean;
  config: any;
}) {
  let value = props.value || [];
  if (typeof value === 'string') {
    value = [value];
  }

  // console.warn('value ===>', value);

  const [fileList, setFileList] = useState(
    []
    // value.map((url, index) => {
    //   return {
    //     uid: index,
    //     name: url,
    //     url,
    //     status: 'done',
    //   };
    // })
  );

  useEffect(() => {
    let value = props.value || [];
    if (typeof value === 'string') {
      value = [value];
    }
    setFileList(
      value.map((url, index) => {
        let name = url.replace(
          'https://softwarecost.oss-cn-chengdu.aliyuncs.com/expert/picture/',
          ''
        );
        // 将名称中的时间戳去掉
        name = name.replace(/\d+_/g, '');
        try {
          // 将中文转义
          name = decodeURIComponent(name);
        } catch (e) {}

        return {
          uid: url,
          name,
          url,
          status: 'done',
        };
      })
    );
  }, [props.value]);

  const handleChange = (files) => {
    setFileList(files);
    handleUpload(files);
    // props.onChange && props.onChange(files.map(file => file.));
  };

  const handleUpload = async (files) => {
    const waitUploadFiles = files.filter((file) => file.status === 'init');
    const doneUploadFiles = files.filter((file) => file.status === 'done');
    const callbackUrls = doneUploadFiles.map((file) => file.url);
    if (waitUploadFiles.length === 0) {
      props.onChange && props.onChange(callbackUrls);
      return;
    } else {
      const uploadFiles = [];
      for (const file of waitUploadFiles) {
        uploadFiles.push(file.originFile);
      }
      uploadToOss(
        uploadFiles,
        (urls) => {
          props.onChange &&
            props.onChange([...fileList.map((i) => i.url), ...urls]);
        },
        props.forbidWaterMark
      );
    }
  };

  const getStsConfig = async () => {
    const response = await commonOssStsTokenGet({});
    const client = new OSS({
      // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
      region: response.data.config.region,
      // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
      accessKeyId: response.data.config.accessKeyId,
      accessKeySecret: response.data.config.accessKeySecret,
      // 从STS服务获取的安全令牌（SecurityToken）。
      stsToken: response.data.config.securityToken,
      // 填写Bucket名称。
      bucket: response.data.config.bucket,
    });
    return {
      client,
      config: response.data.config,
    };
  };

  return (
    <Upload
      listType="picture-card"
      multiple={false}
      autoUpload={false}
      fileList={fileList}
      onChange={handleChange}
      {...props.config}
      onPreview={(file) => {
        Modal.info({
          title: '图片预览',
          footer: null,
          content: (
            <img
              src={file.url || URL.createObjectURL(file.originFile)}
              style={{
                maxWidth: '100%',
              }}
            ></img>
          ),
        });
      }}
    />
  );
}
