import React, { useEffect, useState } from 'react';
import {
  Divider,
  Image,
  Modal,
  Space,
  Typography,
} from '@arco-design/web-react';
import { GlobalState } from '@/store';
import { useSelector, useDispatch } from 'react-redux';
import MemberTypeChoose from './MemberTypeChoose';
import LoginForm from './LoginForm';
import RegisterForm from './RegistForm';
import ForgetForm from './ForgetForm';
const LoginModal = () => {
  const loginVisible = useSelector((state) => state.loginVisible);
  const dispatch = useDispatch();

  const [loginType, setLoginType] = useState(0);
  const [userType, setUserType] = useState(0);

  useEffect(() => {
    setLoginType(0);
  }, [loginVisible]);

  return (
    <Modal
      title={''}
      visible={loginVisible}
      footer={null}
      style={{ width: 500, minHeight: 500 }}
      unmountOnExit
      maskClosable={false}
      focusLock={false}
      escToExit={false}
      onCancel={() => {
        dispatch({
          type: 'update-login-visible',
          payload: { loginVisible: false },
        });
      }}
    >
      <div style={{ paddingTop: 50, paddingBottom: 50 }}>
        {/* 选择登录方式 */}
        {loginType === 0 && (
          <MemberTypeChoose
            onUserTypeChange={(uType) => {
              setUserType(uType);
              setLoginType(1);
            }}
          />
        )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {loginType === 1 && (
            <LoginForm
              userType={userType}
              onTypeChange={(loginType) => {
                setLoginType(loginType);
              }}
            />
          )}
          {loginType === 2 && (
            <RegisterForm
              userType={userType}
              onTypeChange={(loginType) => {
                setLoginType(loginType);
              }}
            />
          )}
          {loginType === 3 && (
            <ForgetForm
              userType={userType}
              onTypeChange={(loginType) => {
                setLoginType(loginType);
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default LoginModal;
