import React from 'react';
export const protocolContent = (
  <div style={{ height: 800, overflowY: 'auto' }}>
    <p>
      欢迎访问四川省软件行业协会信息化项目评价专委会（以下简称“我们”）“数字专家库”平台。本隐私协议旨在说明我们如何收集、使用、保护及处理您在使用“数字专家库”平台时提供的个人及专家评审会议信息。
      <strong>
        您使用或在我们更新本协议后继续使用我们的产品或服务，即意味着您同意本协议(含更新版本)内容，并且同意我们按照本协议处理您的相关信息。如您不同意本协议或其中的任何条款，您可以选择停止注册或停止使用，同时本网站将无法再为您提供相关服务。
      </strong>
    </p>
    <p style={{ fontWeight: 'bold' }}>1. 信息收集</p>
    <p>
      用户和专家信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。我们可能收集以下类型的个人信息：
    </p>
    <p>
      <strong>浏览信息：</strong>
      包括IP地址、设备信息、浏览器类型及Cookie等，用于优化网站性能和用户体验。
    </p>
    <p>
      <strong>用户直接提供信息：</strong>包含用户姓名、电话号码。
    </p>
    <p>
      <strong>专家直接提供信息：</strong>
      基本信息（包括个人姓名、生日、性别、住址、个人电话号码、电子邮箱、银行卡及开户行信息）；专家身份信息（包括身份证号码、职业技能证书、职业资格证书、专家证书等）。
    </p>
    <p>
      <strong>专家评审会议信息：</strong>
      包含会议名称、主题、评审方式、地点、时间、费用、会议资料等。
    </p>
    <p>
      <strong>第三方来源：</strong>
      在合法范围内，我们可能从公开资源或合作伙伴处获得补充信息，以更好地服务于您。
    </p>
    <p style={{ fontWeight: 'bold' }}>
      2. 我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：{' '}
    </p>
    <p>（1）向您提供服务；</p>
    <p>
      （2）在我们提供服务时，用于身份验证、客户服务、存档和备份用途，确保我们向您提供的产品和服务的安全性；
    </p>
    <p>（3）帮助我们设计新服务，改善我们现有服务；</p>
    <p>
      （4）使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应；
    </p>
    <p>（5）软件认证或管理软件升级；</p>
    <p>（6）让您参与有关我们产品和服务的调查。</p>
    <p>
      为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
    </p>
    <p style={{ fontWeight: 'bold' }}>
      3.
      我们不会与除本公司以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：
    </p>
    <p>（1）事先获得您明确的同意或授权；</p>
    <p>
      （2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；
    </p>
    <p>
      （3）在法律法规允许的范围内，为维护我们的关联方或合作伙伴、您或其他我们的用户或社会公众利益、财产或安全免遭损害而有必要提供；
    </p>
    <p>
      （4）只有共享您的信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；
    </p>
    <p>（5）应您需求为您处理您与他人的纠纷或争议；</p>
    <p>（6）符合与您签署的相关协议或其他的法律文件约定所提供；</p>
    <p>（7）基于符合法律法规的社会公共利益而使用。</p>
    <p style={{ fontWeight: 'bold' }}>4.如何共同保护和保存您的个人信息</p>
    <p>
      （1）我们非常重视个人信息安全，并采取一切合理可行数据安全技术措施，保护您的个人信息。
    </p>
    <p>
      （2）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。
    </p>
    <p>
      （3）如果您对我们的个人信息保护有任何疑问，可通过本网站的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的帐号密码及个人信息发生泄露，请您立即联络我们，以便我们采取相应措施。
    </p>
    <p style={{ fontWeight: 'bold' }}>5. Cookie与类似技术</p>
    <p>
      我们使用Cookie和其他跟踪技术来改善网站功能、分析流量及提供个性化内容。您可以通过浏览器设置管理Cookie偏好。
    </p>
    <p style={{ fontWeight: 'bold' }}>6. 用户权利</p>
    <p>您有权：</p>
    <p>访问、更正您的个人信息。</p>
    <p>请求删除您的部分或全部个人信息。</p>
    <p>撤销对个人信息使用的同意（不影响撤回前基于同意的处理活动的合法性）。</p>
    <p>提出有关个人信息处理的投诉或疑问。</p>
    <p style={{ fontWeight: 'bold' }}>7. 联系我们</p>
    <p>
      对于任何关于本隐私协议的问题、疑虑或行使上述权利的请求，请通过以下方式联系我们：
    </p>
    <p>电子邮件：1163584679@qq.com</p>
    <p>电话：18583782555</p>
    <p>地址：成都市武侯区武科西一路85号鼎晟国际3栋503</p>
    <p style={{ fontWeight: 'bold' }}>8. 更新与生效</p>
    <p>
      我们保留随时更新本隐私协议的权利。任何重大变更将在网站上公布，并在必要时通过您提供的联系方式通知您。
    </p>
    <p style={{ fontWeight: 'bold' }}>9. 争议解决</p>
    <p>
      因双方就本协议的签订、履行或解释发生争议，双方应持平等、友好、争取合理解决问题的态度协商解决；
      <strong>
        如协商未成，任何一方均应向四川省软件行业协会信息化项目评价专委会所在地人民法院提起诉讼。
      </strong>
    </p>
    <p>最后更新时间：2024年7月14日</p>
  </div>
);
