import CustomUpload from './Upload';
import { inviteFieldSettingList } from '@/request/api';
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Message,
  Radio,
  Spin,
} from '@arco-design/web-react';
import { useSelector } from 'react-redux';
import useForm from '@arco-design/web-react/es/Form/useForm';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
const InviteFormCard = React.forwardRef((props, ref) => {
  // 使用useImperativeHandle来暴露函数给父组件
  React.useImperativeHandle(ref, () => ({
    getValues: async () => {
      try {
        await form.validate();
      } catch (e) {
        return;
      }
      const values = form.getFieldsValue();
      return values;
    },
  }));
  const [form] = useForm();
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [inviteFieldList, setInviteFieldList] = useState([]);
  const formValues = {};
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await inviteFieldSettingList({});
      setInviteFieldList(response.data.list || []);
    } catch (e) {
      console.warn('e', e);
    }
    setLoading(false);
  };

  const onFormValuesChange = () => {
    const updatedInviteFieldList = inviteFieldList.map((item) => {
      const isRequired = checkIsRequired(item);
      return { ...item, isRequired };
    });
    setInviteFieldList(updatedInviteFieldList);
  };
  // 检查是否必填
  const checkIsRequired = (item) => {
    // 检查是否在其他字段中被忽略了
    const requiredOmitFieldSettingId = item.requiredOmitFieldSettingId;
    const requiredOmitFieldSettingValues = item.requiredOmitFieldSettingValue;
    console.warn('requiredOmitFieldSettingId', requiredOmitFieldSettingId);
    console.warn(
      'requiredOmitFieldSettingValues',
      requiredOmitFieldSettingValues
    );
    if (requiredOmitFieldSettingId) {
      // 如果设置了忽略值
      if (requiredOmitFieldSettingValues.length > 0) {
        console.warn(
          'form.getFieldValue(`field-${requiredOmitFieldSettingId}`)',
          form.getFieldValue(`field-${requiredOmitFieldSettingId}`)
        );
        // 如果设置了值
        if (
          requiredOmitFieldSettingValues.indexOf(
            form.getFieldValue(`field-${requiredOmitFieldSettingId}`)
          ) > -1
        ) {
          return false;
        }
      } else {
        // 如果没有设置忽略值，那么只要有值，就不显示
        if (form.getFieldValue(`field-${requiredOmitFieldSettingId}`)) {
          return false;
        }
      }
      return true;
    }

    return item.isRequired;
  };

  const userInfo = useSelector((state) => state.userInfo);
  const token = useSelector((state) => state.token);
  const [isLogined, setIsLogined] = useState(false);

  useEffect(() => {
    if ((userInfo?.id || 0) > 0 && token) {
      setIsLogined(true);
    } else {
      setIsLogined(false);
    }
  }, [token, userInfo]);

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Spin loading={loading} style={{ display: 'block', marginTop: 8 }}>
      <Form
        form={form}
        autoComplete="off"
        style={{ width: '600px' }}
        disabled={!isLogined}
        onChange={onFormValuesChange}
      >
        {inviteFieldList.map((item) => (
          <Form.Item
            key={item.id}
            label={item.name}
            field={`field-${item.id}`}
            rules={[
              {
                required: item.isRequired,
                message: `该项是必填项`,
              },
            ]}
            extra={item.remark || ''}
          >
            {item.type === 1 ? (
              <Input placeholder={`请输入`} />
            ) : item.type === 2 ? (
              <CustomUpload
                config={{
                  disabled: !isLogined,
                  limit: item.maxTotal,
                  accept: 'image/*',
                }}
              />
            ) : item.type === 3 ? (
              <CustomUpload
                config={{
                  disabled: !isLogined,
                  limit: item.maxTotal,
                  accept: 'application/pdf',
                  listType: 'text',
                }}
              />
            ) : item.type === 4 ? (
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                showTime
                disabledDate={(current) => {
                  if (item.dateOptionType === 2) {
                    return current.isBefore(dayjs().add(-1, 'day'));
                  } else if (item.dateOptionType === 1) {
                    return current.isAfter(dayjs());
                  }
                  return false;
                }}
              />
            ) : item.type === 5 ? (
              <InputNumber
                placeholder={`请输入`}
                // mode="button"
                precision={2}
                style={{ width: 250 }}
              />
            ) : item.type === 8 ? (
              <Radio.Group
                type="button"
                options={item.valueList?.map((option) => ({
                  label: option,
                  value: option,
                }))}
              />
            ) : null}
          </Form.Item>
        ))}
      </Form>
    </Spin>
  );
});
export default InviteFormCard;
