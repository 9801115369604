import { commonOssStsTokenGet, systemSettingGet } from '@/request/api';
import OSS from 'ali-oss';
// 计算图片的平均颜色值
async function calculateAverageColor(imageBitmap) {
  const canvas = document.createElement('canvas');
  canvas.width = imageBitmap.width;
  canvas.height = imageBitmap.height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(imageBitmap, 0, 0);
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  let r = 0,
    g = 0,
    b = 0;
  for (let i = 0; i < imageData.data.length; i += 4) {
    r += imageData.data[i];
    g += imageData.data[i + 1];
    b += imageData.data[i + 2];
  }
  r /= imageData.data.length / 4;
  g /= imageData.data.length / 4;
  b /= imageData.data.length / 4;
  return { r, g, b };
}

// 根据平均颜色值决定水印颜色
function determineWatermarkColor(averageColor) {
  const brightness =
    (averageColor.r * 299 + averageColor.g * 587 + averageColor.b * 114) / 1000;
  return brightness > 128 ? '#f5f5f5' : '#666666';
}

export const uploadToOss = async (
  files,
  onChange,
  forbidWaterMark?: boolean
) => {
  const callbackUrls = [];
  const { client, config } = await getStsConfig();
  const waterMarkConfig = await getWaterMarkConfig();
  for (let file of files) {
    const fileName = `${new Date().getTime()}_${file.name}`;

    if (
      !forbidWaterMark &&
      waterMarkConfig.text != '' &&
      file.type.includes('image')
    ) {
      // Step 1: 将图片存档
      const rawFileName = `expertRawFile/${fileName}`;
      await client.put(rawFileName, file);

      // 创建两个Canvas，一个用于水印，一个用于图片
      const waterMarkCanvas = document.createElement('canvas');
      const imageCanvas = document.createElement('canvas');
      const image = await createImageBitmap(file);
      const watermarkColor = waterMarkConfig.color;

      // 设置Canvas大小
      waterMarkCanvas.width = imageCanvas.width = image.width;
      waterMarkCanvas.height = imageCanvas.height = image.height;

      // 绘制水印
      const waterMarkCtx = waterMarkCanvas.getContext('2d');
      waterMarkCtx.font = waterMarkConfig.fontSize + 'px';
      waterMarkCtx.fillStyle = watermarkColor;
      // 计算水印文字的宽度和高度
      const textMetrics = waterMarkCtx.measureText(waterMarkConfig.text);
      const textWidth = textMetrics.width;
      const textHeight =
        textMetrics.actualBoundingBoxAscent +
        textMetrics.actualBoundingBoxDescent;

      // 动态计算间隔，确保水印不会重叠
      const spacingX = textWidth + waterMarkConfig.spacingHorn; // 水平方向的间隔，20是额外的间隔
      const spacingY = textHeight + waterMarkConfig.spacing; // 垂直方向的间隔，20是额外的间隔

      // 计算需要多少行列的水印来覆盖整个画布
      const rows = Math.ceil(waterMarkCanvas.height / spacingY);
      const columns = Math.ceil(waterMarkCanvas.width / spacingX);
      for (let row = 0; row < rows; row++) {
        for (let column = 0; column < columns; column++) {
          // 计算基础x和y位置
          const baseX = column * spacingX;
          const baseY = row * spacingY;

          // 添加随机偏移量，这里以水印间隔的一定比例（如1/4）作为最大偏移
          const offsetX = (Math.random() - 0.5) * spacingX * 0.15;
          const offsetY = (Math.random() - 0.5) * spacingY * 0.15;

          // 应用偏移量
          const x = baseX + offsetX;
          const y = baseY + offsetY;

          waterMarkCtx.save(); // 保存当前画布状态
          waterMarkCtx.globalAlpha = waterMarkConfig.colorAlpha / 100; // 设置水印透明度，例如0.5
          waterMarkCtx.translate(x, y); // 移动画布原点到当前水印位置
          waterMarkCtx.rotate((waterMarkConfig.rotate * Math.PI) / 180); // 旋转画布
          waterMarkCtx.fillText(waterMarkConfig.text, 0, 0); // 绘制水印
          waterMarkCtx.restore(); // 恢复画布状态
        }
      }

      // 在图片Canvas上绘制图片
      const imageCtx = imageCanvas.getContext('2d');
      imageCtx.drawImage(image, 0, 0);

      // 将水印Canvas的内容绘制到图片Canvas上，实现水印在下面的效果
      imageCtx.drawImage(waterMarkCanvas, 0, 0);

      // Step 4 & 5: 将Canvas转换为Blob，然后转换为File
      const blob: BlobPart = await new Promise((resolve) =>
        imageCanvas.toBlob(resolve)
      );
      file = new File([blob], `${file.name}`, {
        type: 'image/png',
      });
    }

    // Step 6: 上传到OSS
    const fullFileName = `expert/picture/${fileName}`;
    const result = await client.put(fullFileName, file);
    result.url = result.url.replace('http://', 'https://');
    callbackUrls.push(result.url);

    // console.warn(file, 'file ==');
    // // const suffix = file.name.split('.').pop();
    // const fileName = `expert/picture/${new Date().getTime()}_${file.name}`;
    // const result = await client.put(fileName, file);
    // console.warn(result);
    // // 将url的http:// 替换成 https://
    // result.url = result.url.replace('http://', 'https://');
    // callbackUrls.push(result.url);
  }
  console.warn(files);
  console.warn(callbackUrls);
  onChange && onChange(callbackUrls);
};

export const getStsConfig = async () => {
  const response = await commonOssStsTokenGet({});
  const client = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: response.data.config.region,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: response.data.config.accessKeyId,
    accessKeySecret: response.data.config.accessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: response.data.config.securityToken,
    // 填写Bucket名称。
    bucket: response.data.config.bucket,
  });
  return {
    client,
    config: response.data.config,
  };
};

export const getWaterMarkConfig = async () => {
  const response = await systemSettingGet({
    keys: [
      'ImageWaterMarkText',
      'ImageWaterMarkFontSize',
      'ImageWaterMarkRotate',
      'ImageWaterMarkSpacing',
      'ImageWaterMarkColor',
      'ImageWaterMarkColorAlpha',
      'ImageWaterMarkSpacingHorn',
    ],
  });
  const waterMarkConfig = {
    text: '',
    color: '#fff',
    colorAlpha: 100,
    fontSize: 18,
    rotate: 45,
    spacing: 20,
    spacingHorn: 20,
  };
  (response.data.list || []).forEach((item) => {
    if (item.key === 'ImageWaterMarkText') {
      waterMarkConfig.text = item.value;
    }
    if (item.key === 'ImageWaterMarkFontSize') {
      waterMarkConfig.fontSize = +item.value;
    }
    if (item.key === 'ImageWaterMarkRotate') {
      waterMarkConfig.rotate = +item.value;
    }
    if (item.key === 'ImageWaterMarkSpacing') {
      waterMarkConfig.spacing = +item.value;
    }
    if (item.key === 'ImageWaterMarkColor') {
      waterMarkConfig.color = item.value;
    }
    if (item.key === 'ImageWaterMarkColorAlpha') {
      waterMarkConfig.colorAlpha = +item.value;
    }
    if (item.key === 'ImageWaterMarkSpacingHorn') {
      waterMarkConfig.spacingHorn = +item.value;
    }
  });
  return waterMarkConfig;
};
