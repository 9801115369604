import CustomUpload from './Upload';
import { Form, Input, InputNumber, Spin } from '@arco-design/web-react';
import { useSelector } from 'react-redux';
import useForm from '@arco-design/web-react/es/Form/useForm';
import React, { useEffect, useState } from 'react';
import { regexMobile } from '@/utils/util';
const InviteContactFormCard = React.forwardRef((props, ref) => {
  // 使用useImperativeHandle来暴露函数给父组件
  React.useImperativeHandle(ref, () => ({
    getValues: async () => {
      try {
        await form.validate();
      } catch (e) {
        return;
      }
      const values = form.getFieldsValue();
      return values;
    },
  }));
  const [form] = useForm();
  const userInfo = useSelector((state) => state.userInfo);
  const token = useSelector((state) => state.token);
  const [isLogined, setIsLogined] = useState(false);

  useEffect(() => {
    if ((userInfo?.id || 0) > 0 && token) {
      setIsLogined(true);
      form.setFieldValue('name', userInfo.name);
      form.setFieldValue('mobile', userInfo.mobile);
    } else {
      setIsLogined(false);
      form.setFieldValue('name', '');
      form.setFieldValue('mobile', '');
    }
  }, [token, userInfo]);
  return (
    <Spin loading={false} style={{ display: 'block', marginTop: 8 }}>
      <Form
        form={form}
        autoComplete="off"
        style={{ width: '600px' }}
        disabled={!isLogined}
      >
        <Form.Item
          field="company"
          label={<span>单位名称</span>}
          required={true}
          rules={[{ required: true, message: '请输入' }]}
        >
          <Input placeholder="请输入" style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          field="name"
          label={<span>联系人姓名</span>}
          required={true}
          rules={[{ required: true, message: '请输入姓名' }]}
        >
          <Input placeholder="请输入姓名" style={{ width: 200 }} />
        </Form.Item>
        <Form.Item
          field="mobile"
          label={<span>手机号</span>}
          required={true}
          rules={[
            {
              required: true,
              message: '请输入',
            },
            {
              validator(value, cb) {
                if (!regexMobile(value)) {
                  return cb('请输入正确的11位手机号');
                }
                return cb();
              },
            },
          ]}
        >
          <Input
            maxLength={11}
            placeholder="请输入手机号"
            style={{ width: 200 }}
          />
        </Form.Item>
      </Form>
    </Spin>
  );
});
export default InviteContactFormCard;
