import React from 'react';
import { Card, Grid, Image, Space, Typography } from '@arco-design/web-react';
const { Row, Col } = Grid;
import IconExpert from '@/assets/icon/user-v.png';
import IconUser from '@/assets/icon/user.png';
const MemberTypeChoose = (props: {
  onUserTypeChange: (userType: number) => void;
}) => {
  return (
    <div
      style={
        {
          // paddingTop: 50,
        }
      }
    >
      <div style={{ textAlign: 'center', fontSize: 28, marginBottom: 40 }}>
        请选择登录方式
      </div>
      <Card
        className="card-with-icon-hover"
        hoverable
        style={{
          width: 260,
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
          borderRadius: '10px',
          cursor: 'pointer',
          margin: '0 auto',
        }}
        onClick={() => {
          props.onUserTypeChange(1);
        }}
      >
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Space>
            {/* <Space direction="vertical" align="center"> */}
            <Image src={IconUser} height={44} preview={false} />
            <Typography.Text style={{ fontSize: 24 }}>用户登录</Typography.Text>
          </Space>
        </Space>
      </Card>
      <Card
        className="card-with-icon-hover"
        hoverable
        style={{
          width: 260,
          boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.08)',
          borderRadius: '10px',
          cursor: 'pointer',
          margin: '0 auto',
          marginTop: 50,
        }}
        onClick={() => {
          props.onUserTypeChange(2);
        }}
      >
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Space>
            {/* <Space direction="vertical" align="center"> */}
            <Image
              src={IconExpert}
              height={40}
              preview={false}
              style={{ borderRadius: 5 }}
            />
            <Typography.Text style={{ fontSize: 24 }}>专家登录</Typography.Text>
          </Space>
        </Space>
      </Card>
    </div>
  );
};

export default MemberTypeChoose;
