import {
  Button,
  Image,
  Space,
  Tag,
  Tooltip,
  Typography,
} from '@arco-design/web-react';
import Item from '@arco-design/web-react/es/Breadcrumb/item';
import React from 'react';
import './ExpertCard.less';
import IconXunZhang from '@/assets/icon/xunzhang.png';
import IconNan from '@/assets/icon/nan.png';
import IconNv from '@/assets/icon/nv.png';
import IconBirthday from '@/assets/icon/nianling.png';
import IconCity from '@/assets/icon/chengshishenghuo.png';
const ExpertUserInfoMiniCard = (props: { expert: any; single?: boolean }) => {
  return props.expert === null ? (
    <></>
  ) : (
    <div
      className={`expert-userinfo-mini-card ${
        props.single ? 'expert-userinfo-mini-card--single' : ''
      }`}
    >
      <div className="expert-userinfo-mini-card__userinfo">
        <div className="expert-userinfo-mini-card__userinfo--avatar">
          <Image
            src={props.expert.avatar}
            style={{
              objectFit: 'cover',
              borderRadius: 5,
            }}
            height={108}
            width={78}
          />
        </div>
        <div className="expert-userinfo-mini-card__userinfo--content">
          <div className="nickname">
            <div className="name">{props.expert.name}</div>
            {/* <div className="professional iconWithText">
              <div className="icon">
                <Image src={IconXunZhang} height={20} />
              </div>
              <div className="text">啊是</div>
            </div> */}
          </div>
          {/* <div className="professional">
            <div className="iconWithText">
              <div className="icon">
                <Image src={IconXunZhang} height={20} preview={false} />
              </div>
              <div className="text">
                {(props.expert.professionalFields || []).map(
                  (item) => item.name
                )}
              </div>
            </div>
          </div> */}
          {/* <div className="meta">
            <div className="iconWithText" style={{ marginRight: 10 }}>
              <div className="icon">
                <Image
                  src={props.expert.gender == 1 ? IconNan : IconNv}
                  height={18}
                  preview={false}
                />
              </div>
              <div className="text">
                {props.expert.gender == 1 ? '男' : '女'}
              </div>
            </div>
            <div className="iconWithText" style={{ marginRight: 10 }}>
              <div className="icon">
                <Image src={IconBirthday} height={18} preview={false} />
              </div>
              <div className="text">{props.expert.birthday}</div>
            </div>
          </div> */}
          <div className="address">
            <div className="iconWithText" style={{ marginRight: 10 }}>
              <div className="icon">
                <Image src={IconCity} height={18} preview={false} />
              </div>
              <div className="text">现居{props.expert.city}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="expert-userinfo-mini-card__professional">
        <Tooltip
          content={(props.expert.professionalFields || []).map(
            (item) => item.name
          )}
          position="tl"
        >
          <div className="iconWithText">
            <div className="icon">
              <Image src={IconXunZhang} height={20} preview={false} />
            </div>
            <div className="text">
              {(props.expert.professionalFields || []).map((item) => item.name)}
            </div>
          </div>
        </Tooltip>
      </div>
    </div>
  );
};
export default ExpertUserInfoMiniCard;
