import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import {
  Layout,
  Menu,
  Breadcrumb,
  Spin,
  Alert,
  Affix,
  Image,
  Badge,
} from '@arco-design/web-react';
import {
  IconCloseCircle,
  IconDashboard,
  IconSettings,
  IconUserGroup,
} from '@arco-design/web-react/icon';
import { useSelector, useDispatch } from 'react-redux';
import qs from 'query-string';
import NProgress from 'nprogress';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import useRoute, { IRoute } from '@/routes';
import useLocale from './utils/useLocale';
import getUrlParams from './utils/getUrlParams';
import lazyload from './utils/lazyload';
import { GlobalState } from './store';
import styles from './style/layout.module.less';
import LoginModal from './components/LoginModal';
import IconReceive from '@/assets/icon/shouji.png';
import InviteFormModal from './components/InviteFormModal';

const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Sider = Layout.Sider;
const Content = Layout.Content;

function getIconFromKey(key) {
  switch (key) {
    case 'welcome':
      return <IconDashboard className={styles.icon} />;
    case 'mechanism':
      return <IconUserGroup className={styles.icon} />;
    case 'setting':
      return <IconSettings className={styles.icon} />;
    default:
      return <div className={styles['icon-empty']} />;
  }
}

function getFlattenRoutes(routes) {
  const res = [];
  function travel(_routes) {
    _routes.forEach((route) => {
      const visibleChildren = (route.children || []).filter(
        (child) => !child.ignore
      );
      if (route.key && (!route.children || !visibleChildren.length)) {
        try {
          route.component = lazyload(() => import(`./pages/${route.key}`));
          res.push(route);
        } catch (e) {
          console.error(e);
        }
      }
      if (route.children && route.children.length) {
        travel(route.children);
      }
    });
  }
  travel(routes);
  return res;
}

function PageLayout() {
  const history = useHistory();
  const pathname = history.location.pathname;
  const currentComponent = qs.parseUrl(pathname).url.slice(1);

  const userLoading = useSelector((state) => state.userLoading);
  const { settings, userInfo, inviteExpertIds } = useSelector(
    (state: GlobalState) => state
  );
  const dispatch = useDispatch();

  const [routes, defaultRoute] = useRoute(userInfo?.permissions);

  const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes]);

  return (
    <Layout className={styles.layout}>
      <LoginModal />
      <InviteFormModal />
      {userLoading ? (
        <Spin className={styles['spin']} />
      ) : (
        <Layout className={styles['layout-content']}>
          <div className={styles['layout-content-wrapper']}>
            <Content>
              <Switch>
                {flattenRoutes.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={`/${route.key}`}
                      component={route.component}
                    />
                  );
                })}
                <Route exact path="/">
                  <Redirect to={`/${defaultRoute}`} />
                </Route>
                <Route
                  path="*"
                  component={lazyload(() => import('./pages/exception/403'))}
                />
              </Switch>
            </Content>
          </div>
          <Footer />
        </Layout>
      )}
      {inviteExpertIds.length > 0 && userInfo.type !== 2 && (
        <div
          className={styles.fixed_button}
          onClick={(e) => {
            e.stopPropagation(); // 阻止事件冒泡
            dispatch({
              type: 'update-invite-visible',
              payload: {
                inviteFormVisible: true,
              },
            });
          }}
        >
          <div className={styles.fixed_button__icon}>
            <Badge text={inviteExpertIds.length + `个`}>
              <Image src={IconReceive} preview={false} height={50} />
            </Badge>
          </div>
          <div className={styles.fixed_button__title}>待提交邀请</div>
          <div
            className={styles.fixed_button__closebtn}
            onClick={(e) => {
              e.stopPropagation(); // 阻止事件冒泡
              dispatch({
                type: 'update-inviteIds',
                payload: {
                  inviteExpertIds: [],
                },
              });
            }}
          >
            <IconCloseCircle
              fontSize={24}
              style={{ color: 'rgba(0,0,0,.3)' }}
            />
          </div>
        </div>
      )}
    </Layout>
  );
}

export default PageLayout;
