import defaultSettings from '../settings.json';
export interface GlobalState {
  settings?: typeof defaultSettings;
  userInfo?: {
    id?: number;
    account?: string;
    nickname?: string;
    isSuper?: number;
    permissions: string[];
    remark?: string;
  };
  userLoading?: boolean;
  loginVisible: boolean;
  token?: string;
  inviteExpertIds: number[];
  inviteFormVisible: boolean;
}

const initialState: GlobalState = {
  settings: defaultSettings,
  userInfo: {
    permissions: [],
  },
  userLoading: false,
  loginVisible: false,
  token: '',
  inviteExpertIds: [],
  inviteFormVisible: false,
};

export default function store(state = initialState, action) {
  switch (action.type) {
    case 'update-settings': {
      const { settings } = action.payload;
      return {
        ...state,
        settings,
      };
    }
    case 'update-userInfo': {
      const { userInfo = initialState.userInfo, userLoading } = action.payload;
      return {
        ...state,
        userLoading,
        userInfo,
      };
    }
    case 'update-login-visible': {
      console.warn('hello', action.payload);
      const { loginVisible } = action.payload;
      return {
        ...state,
        loginVisible,
      };
    }
    case 'update-token': {
      const { token } = action.payload;
      return {
        ...state,
        token,
      };
    }
    case 'update-invite-visible': {
      const { inviteFormVisible } = action.payload;
      return {
        ...state,
        inviteFormVisible,
      };
    }
    case 'update-inviteIds': {
      const { inviteExpertIds } = action.payload;
      // 将ID去重
      // 将数据存到本地
      localStorage.setItem('inviteExpertIds', JSON.stringify(inviteExpertIds));
      return {
        ...state,
        inviteExpertIds: Array.from(new Set(inviteExpertIds)),
      };
    }
    default:
      return state;
  }
}
